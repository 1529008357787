<template src="./settings.html"></template>

<script>
import Http from '@/shared/http-config'
import ImgColorPicker from 'vue-img-color-picker';
import Editor from '@tinymce/tinymce-vue';
import tinymce from '@/shared/tinymce';
import DisplayWebsite from '@/shared/DisplayWebsite'
import moment from 'moment'
import {gmapApi} from 'vue2-google-maps'

export default {
  name: "Settings",
  components: {ImgColorPicker, 'tinymceEditor': Editor, DisplayWebsite},
  mixins: [tinymce],
  data: function () {
    return {
      moment: moment,
      user: null,
      google_place: {},
      settings: {
        logo: '',
        websiteVideos: {
          url: []
        },
        websiteTags: {analytics: '', facebook: ''},
        websiteWidgets: [],
        googlePlaceID: ''
      },
      possibleWidgets: [
        {value: 'theory', text: 'DEIN THEORIEUNTERRICHT'},
        {value: 'seminars', text: 'DEINE KURSE & SEMINARE'},
        {value: 'guestbook', text: 'FAHRSCHÜLER ÜBER UNS'},
        {value: 'team', text: 'UNSER TEAM'},
        {value: 'vehicles', text: 'UNSERE FAHRZEUGE'}
      ],
      displayWebsite: [
        {
          value: 1,
          name: 'Auto',
          icon: 'https://files.fahrschul-system.de/1_0/klasseb.png'
        },
        {
          value: 2,
          name: 'Motorrad',
          icon: 'https://files.fahrschul-system.de/1_0/klassea.png'
        },
        {
          value: 4,
          name: 'BKF',
          icon: 'https://files.fahrschul-system.de/1_0/klassec.png'
        },
      ]
    }
  },
  computed: {
    google: gmapApi
  },
  mounted: function(){
    let self = this;
    self.$store.dispatch('setIntroJsEnabled', false);

    if(typeof(self.$refs.placeSearchMap.$mapPromise) !== "undefined"){
      /* Todo: find solution for $mapPromise in unit test */
      /* istanbul ignore next */
      self.$refs.placeSearchMap.$mapPromise.then((map) => {
        const input = document.getElementById("pac-input");
        const autocomplete = new self.google.maps.places.Autocomplete(input);
        autocomplete.bindTo("bounds", map);
        autocomplete.setFields(["place_id", "geometry", "name", "formatted_address", 'rating', 'user_ratings_total']);
        map.controls[self.google.maps.ControlPosition.TOP_LEFT].push(input);
        const marker = new self.google.maps.Marker({ map: map });
        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          if (!place.geometry) {
            return;
          }

          if (place.geometry.viewport) {
            map.fitBounds(place.geometry.viewport);
          } else {
            map.setCenter(place.geometry.location);
            map.setZoom(17);
          }
          marker.setPlace({
            placeId: place.place_id,
            location: place.geometry.location,
          });
          marker.setVisible(true);
          self.$set(self.settings, 'googlePlaceID', place.place_id);
          self.google_place = place;
        });
      });
    }
  },
  destroyed: function(){
    this.$store.dispatch('setIntroJsEnabled', true);
  },
  created: function(){
    let self = this;
    self.user = self.$store.getters.getUser;
    Http.request('GET', '/website/settings').then(function (settings) {
      if (settings.data.websiteTags === null) {
        settings.data.websiteTags = {};
      }
      self.settings = settings.data;

      setTimeout(function (){
        self.$refs.settingsObserver.validate();
      },500);

      /* Todo: find solution for $mapPromise in unit test */
      /* istanbul ignore next */
      if(self.settings.googlePlaceID.length > 0 && typeof(self.$refs.placeSearchMap.$mapPromise) !== "undefined"){
        self.$refs.placeSearchMap.$mapPromise.then((map) => {
          let request = {
            placeId: self.settings.googlePlaceID,
            fields: ["place_id", "geometry", "name", "formatted_address", 'rating', 'user_ratings_total']
          };
          let service = new self.google.maps.places.PlacesService(map);
          service.getDetails(request, placeDetailsCallback);
        });
      }

      function placeDetailsCallback(place, status) {
        if (status === self.google.maps.places.PlacesServiceStatus.OK) {
          self.google_place = place;
        }
      }
    });
  },
  methods: {
    setDisplayWebsite (value){
      this.settings.displayWebsite = value;
    },
    deleteGooglePlaceID(){
      this.google_place = {};
      this.settings.googlePlaceID = '';
      this.save();
    },
    save () {
      let self = this;
      let formData = new FormData();

      let data = JSON.parse(JSON.stringify(self.settings));
      delete data.logo;
      formData.appendRecursive(data);

      Http.request('POST', '/website/settings', formData).then(function () {
        self.$bvToast.toast('Die Änderungen wurden gespeichert', {
          title: 'Gespeichert',
          variant: 'success',
          autoHideDelay: 5000
        })
      })
      .catch(function (error) {
        if(typeof(error.errors.recaptchaType) !== "undefined"){
          self.settings.recaptchaType = 0;
          self.save();
          self.$store.dispatch('setErrorModal', false);

          self.$bvModal.msgBoxOk('Der reCAPTCHA Code war ungültig. reCAPTCHA wurde deaktiviert.', {
            title: 'Achtung',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'warning',
            centered: true
          });
        }
      });
    },
  }
}
</script>

<style scoped>
.gmaps-location-search {
  background-color: #fff;
  border-radius: 2px;
  border: 1px solid transparent;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 300;
  height: 38px;
  margin-left: 15px;
  margin-top: 10px;
  outline: none;
  padding: 0 11px 0 13px;
  text-overflow: ellipsis;
  width: 90%;
}
</style>
